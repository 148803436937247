import React from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Grid } from '@material-ui/core';
import Img from 'gatsby-image';
import Chip from '@material-ui/core/Chip';
import {
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  FacebookShareButton,
} from 'react-share';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import SEO from '../components/seo';
import AllServices from '../components/allServices';
import DlaczegoWarto from '../components/dlaczegoWarto';

deckDeckGoHighlightElement();

const { kebabCase } = require('../utils/slugs');

export default function Template({ data, location }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const featuredImg = frontmatter.featuredImage.childImageSharp.fluid;
  return (
    <>
      <SEO title={frontmatter.metaTitle} description={frontmatter.desc} />
      <Container>
        <Grid spacing={2} container className="post-cont">
          <Grid item xs={12} sm={12} md={7}>
            <article>
              <h1>{frontmatter.title}</h1>
              <div style={{ display: 'flex', marginTop: '20px' }}>
                <div>
                  <img
                    alt={frontmatter.author}
                    src="/img/pkuffel.jpg"
                    style={{
                      width: '50px',
                      borderRadius: '100px',
                    }}
                  />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <address
                    className="author"
                    style={{
                      fontStyle: 'normal',
                      marginBottom: '8px',
                    }}
                  >
                    {frontmatter.author}
                  </address>
                  {frontmatter.date}
                  {' '}
                  <span className="post-tags-separator">|</span>
                  {' '}
                  <div className="post-tags">
                    {frontmatter.tags.map((tag) => (
                      <>
                        <Chip
                          component={Link}
                          to={`/blog/tag/${kebabCase(tag)}/`}
                          label={tag}
                          size="small"
                          style={{
                            marginBottom: '5px',
                            marginLeft: '5px',
                            verticalAlign: '0%',
                          }}
                        />
                        {' '}
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <Img
                style={{
                  margin: '15px 0px 30px 0px',
                  borderRadius: '12px',
                  boxShadow: '0px 5px 15px #00000045',
                }}
                fluid={featuredImg}
                alt={frontmatter.featuredImageAlt}
              />
              <p>{frontmatter.lead}</p>
              <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </article>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <div className="social-share">
              <p>Udostępnij ten wpis</p>
              <LinkedinShareButton
                title={frontmatter.title}
                summary={frontmatter.lead}
                url={location.href}
                source="iqcode"
              >
                <LinkedinIcon round size={32} />
              </LinkedinShareButton>
              <FacebookShareButton
                hashtag="iqcode"
                quote={frontmatter.lead}
                url={location.href}
              >
                <FacebookIcon round size={32} />
              </FacebookShareButton>
              <TwitterShareButton
                title={frontmatter.title}
                url={location.href}
              >
                <TwitterIcon round size={32} />
              </TwitterShareButton>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Rozważasz
            {' '}
            <span>Projekt IT</span>
            ?
          </h3>
          <h4>Umówmy bezpłatną konsultację projektową!</h4>
          <Link className="btn" to="/contact/">
            kontakt
          </Link>
        </div>
      </div>
      <DlaczegoWarto />
      <AllServices />
    </>
  );
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "DD-MM-YYYY")
                slug
                title
                author
                metaTitle
                desc
                lead
                tags
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                featuredImageAlt
            }
        }
    }
`;
